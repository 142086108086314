.fav-goblal-container{
    min-height: 100vh;
}

.products-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Crea 5 columnas iguales */
    gap: 20px; /* Espaciado entre los elementos */
    width: 80%;
    margin: 0px auto;
    margin-bottom: 30px;
    }
.container-fav p {
    margin-bottom: 20px;
}

.container-fav{
    width: 80%; 
    margin: 20px auto;
    }

.alert{
    background-color: rgb(255, 60, 60);
    color: white;
    padding: 5px;
    text-align: center;
    margin: 0;
}
@media screen and (min-width: 769px) {

    .products-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr); /* Crea 5 columnas iguales */
      gap: 20px; /* Espaciado entre los elementos */
      width: 80%;
      margin: 0px auto;
      margin-bottom: 30px;
      }
    
  }


  