.container{
  display: flex;
  background-color: white;
}

.ais-Hits-list{
  width: 95%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ais-Hits-item {
  flex: 1 1 250px;
  max-width: 300px;
  margin: 10px;
}

/* Buscador filtros css=========================================================*/

.ais-SearchBox-form {
  display: flex; /* Asegura que los elementos del formulario estén en fila */
  width: 75%; /* Ocupa el ancho disponible */
  align-items: center; /* Centra los elementos verticalmente */
  margin: 5px 0;
}

.ais-SearchBox-input {
  border: 1px solid rgb(255, 150, 0); 
  border-radius: 5px;
  padding: 5px 10px; /* Ajusta el padding interno */
  font-size: 16px;
  width: 150px;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  display: flex;
  justify-content: center;
  align-items: center; /* Centra el icono dentro del botón */
  background: transparent; /* Fondo transparente */
  cursor: pointer;
  padding: 5px; /* Evita que el padding modifique el tamaño */
  box-sizing: border-box;
  margin: 2px;
}

.ais-SearchBox-submitIcon,
.ais-SearchBox-resetIcon {
  width: 12px;
  height: 12px;
}
/* Buscador filtros css=========================================================*/
/* Buscador principal css=========================================================*/

.buscador-principal {
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  background-image: url(/public/imgs/main-page-photo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 200px;
}

.buscador-principal .ais-SearchBox-form {
  display: flex; /* Coloca los elementos del formulario en fila */
  align-items: center; /* Alinea verticalmente al centro */
  justify-content: center; /* Centra el formulario dentro del contenedor */
  gap: 10px; /* Espaciado entre el input y los botones */
}

.buscador-principal .ais-SearchBox-input {
  border: 1px solid rgb(255, 150, 0); 
  border-radius: 5px;
  padding: 15px 10px; /* Ajusta el espacio interno del input */
  font-size: 18px;
  width: 24rem; /* Ancho del input */
}
.ais-SearchBox-input::-webkit-search-cancel-button {
  display: none; /* Oculta el botón de cancelación en navegadores basados en WebKit */
}

.buscador-principal .ais-SearchBox-submit,
.buscador-principal .ais-SearchBox-reset {
  width: 45px; 
  height: 45px; 
  margin: 0; /* Elimina márgenes extra */
  background-color: rgb(255, 150, 0); /* Fondo transparente */
  cursor: pointer; /* Añade un cursor de puntero */
}

.buscador-principal .ais-SearchBox-submitIcon,
.buscador-principal .ais-SearchBox-resetIcon {
  width: 15px; 
  height: 15px;
}
/* Buscador principal css=========================================================*/

/* Filtros css=========================================================*/
filter .ul{
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.filter h3{
  margin: 0;
  padding:0;
}

.filter{
  min-width: 12rem;
  max-width: 12rem;
  padding: 1rem;
  border: 2px solid orange; /* Color naranja para el borde */
  border-radius: 10px; /* Bordes redondos */
  margin: 2rem
}

  /* Menu jerarquico =========================================================*/
  /* Estilo para el contenedor de cada item de la lista */
  .ais-HierarchicalMenu-item {
    list-style-type: none; /* Eliminar los puntos de lista predeterminados */
    margin: 5px 0; /* Reducir margen entre los elementos */
  }
  
  .ais-HierarchicalMenu-list {
    padding: 0;
  }
  
  /* Estilo para el enlace */
  .ais-HierarchicalMenu-link {
    display: flex;
    justify-content: space-between; /* Espacio entre la etiqueta y el contador */
    align-items: center;
    padding: 8px 12px;
    text-decoration: none; /* Quitar subrayado */
    color: #333; /* Color del texto */
    border-radius: 4px; /* Bordes redondeados */
    transition: background-color 0.3s ease, padding-left 0.3s ease;
  }
  
  /* Hover: Cambio de color cuando el usuario pasa el mouse */
  .ais-HierarchicalMenu-link:hover {
    background-color: rgb(255, 150, 0);
    color: white;
  }
  
  /* Estilo para la etiqueta */
  .ais-HierarchicalMenu-label {
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
  }
  
  /* Estilo para el contador */
  .ais-HierarchicalMenu-count {
    font-size: 12px;
    color: #797777;
    background-color: #e0e0e0;
    border-radius: 12px;
    padding: 2px 8px;
    text-align: center;
  }
  
  /* --- ESTILOS PARA LOS SUBMENÚS --- */
  
  /* Submenú (primer nivel de subcategoría) */
  .ais-HierarchicalMenu-list--child {
    padding-left: 15px; /* Sangría para diferenciar el submenú */
    border-left: 2px solid rgb(255, 150, 0); /* Línea para resaltar la jerarquía */
    margin-top: 5px;
  }
  
  /* Estilo para los enlaces dentro del submenú */
  .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-link {
    background-color: #f9f9f9; /* Fondo más claro */
    padding-left: 20px; /* Más sangría en el texto */
  }
  
  /* Hover en submenú */
  .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-link:hover {
    background-color: rgb(255, 150, 0); /* Color más suave que el del menú principal */
  }
  
  /* Submenús más profundos (segundo nivel de subcategoría o más) */
  .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-list--child {
    padding-left: 20px;
    border-left: 2px dashed rgb(255, 150, 0); /* Línea punteada para indicar mayor profundidad */
  }
  
  /* Estilo para enlaces en subniveles más profundos */
  .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-link {
    background-color: #f2f2f2;
    padding-left: 30px;
  }
  
  /* --- ESTILOS PARA ELEMENTOS SELECCIONADOS --- */
  
  /* Elemento principal seleccionado */
  .ais-HierarchicalMenu-item--selected > .ais-HierarchicalMenu-link {
    background-color: rgb(255, 150, 0);
    color: white;
    font-weight: bold;
  }
  
  /* Subcategoría seleccionada */
  .ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-link--selected {
    background-color: rgb(255, 150, 0);
    color: #fff;
    font-weight: bold;
  }
  /* Menu jerarquico =========================================================*/

  .ais-ClearRefinements{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .ais-ClearRefinements-button{
    font-weight: bold;
  }
  /* Contenedor principal: se muestra en línea */
  .ais-RefinementList {
    display: flex;
    flex-wrap: wrap; /* Permite que los filtros se ajusten en varias líneas si es necesario */
    gap: 8px; /* Espaciado entre filtros */
  }
  
  /* Lista de elementos */
  .ais-RefinementList-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Cada opción de refinamiento */
  .ais-RefinementList-item {
    display: flex;
    align-items: center;
    padding: 6px 0px;
    border-radius: 4px; /* Forma de etiqueta redondeada */
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
  }
  .ais-RefinementList-label{
    display: flex;
    justify-content: space-between; /* Espacio entre la etiqueta y el contador */
    align-items: center;
    width: 100%;
    margin-right: 10px;
  }
  .ais-RefinementList-item:hover {
    background-color: rgb(255, 150, 0);
    color: white;
  }
  
  /* Checkbox */
  .ais-RefinementList-checkbox {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    accent-color: rgb(255, 150, 0); /* Cambia el color del checkbox */
    cursor: pointer;
  }
  
  /* Texto del label */
  .ais-RefinementList-labelText {
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
  }
  
  /* Contador */
  .ais-RefinementList-count {
    font-size: 12px;
    color: #797777;
    background-color: #e0e0e0;
    border-radius: 12px;
    padding: 2px 6px;
    margin-left: 8px;
    margin-left: auto;
  }
  
  /* Elementos seleccionados */
  .ais-RefinementList-item--selected {
    background-color: rgb(255, 150, 0);
    color: white;
    font-weight: bold;
  }
  
  .ais-RefinementList-item--selected .ais-RefinementList-count {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
  }
/* Filtros css=========================================================*/

/* Precio Range css=========================================================*/
/* Contenedor del formulario */
.ais-RangeInput-form {
  display: flex;
  align-items: center;
  gap: 5px; /* Espaciado entre elementos */
  padding: 10px 0px;
  width: fit-content; /* Ajusta el tamaño según el contenido */
}

/* Contenedor de cada input */
.ais-RangeInput-label {
  background-color: white;
  border-radius: 10px;
  padding: 4px;
  border: 1px solid #ccc;
}

/* Inputs de rango */
.ais-RangeInput-input {
  border: none;
  text-align: center;
  font-size: 12px;
}

/* Separador "to" */
.ais-RangeInput-separator {
  font-size: 14px;
  font-weight: bold;
  color: #666;
}

/* Botón de envío */
.ais-RangeInput-submit {
  background-color: rgb(255, 150, 0);
  color: white;
  border: none;
  padding: 6px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Precio Range css=========================================================*/

/* Paginación css=========================================================*/

.ais-Pagination-link {
  display: inline-block;
  padding: 8px 12px;
  margin-right: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.ais-Pagination-link:hover {
  background-color: #f0f0f0;
}

.ais-Pagination-link.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.ais-Pagination-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ais-Pagination-item {
  display: inline-block;
}

.ais-Pagination-item.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.pagination-container {
  display: flex;
  justify-content: center;
  padding: 30px;
}

.ais-Pagination-item--selected a{
  font-weight: bold; /* Hace que el texto esté en negrita */
  color: orange; /* Cambia el color del texto a naranja */
  /* Agrega otros estilos de resaltado según sea necesario */
}
/* Paginación css=========================================================*/

.compartir {
  margin: 15px 0;
}
  
/* Stats-sort css=========================================================*/

.stats-sort {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  margin-right: 10%;
  margin-bottom: 10px ;
}

/* Sort css=========================================================*/

/* Estilo base para el select */
.ais-SortBy-select {
  font-size: 16px;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  width: 150px; /* Ajusta el ancho según sea necesario */
}


.container-p{
  width: 70%;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
}

.ais-RefinementList-item:not(:not(:contains("unknown"))) {
  display: none;
}
.ais-RefinementList {
  position: relative;
  display: block; /* Asegura que todo esté en bloque */
  margin: 0px;
}

.ais-RefinementList-list{
  margin: 0px;
}


/* Botón "Show More" */
.ais-RefinementList-showMore, .ais-HierarchicalMenu-showMore {
  display: block;
  width: 100%; /* Que ocupe todo el ancho disponible */
  padding: 8px 12px;
  margin: 10px 0 20px 0;
  text-align: center;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(255, 150, 0); /* Color de fondo naranja */
  color: white; /* Texto en blanco */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover: Cambio de color y ligero efecto de elevación */
.ais-RefinementList-showMore:hover {
  background-color: #e07b00;
  transform: scale(1.05);
}

/* Efecto al hacer clic */
.ais-RefinementList-showMore:active {
  background-color: #c86a00;
  transform: scale(0.98);
}

/* Media Queries para Responsividad */
@media (max-width: 768px) {
  .ais-Hits-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
  .container {
    flex-direction: column;
  }

  .filter-container, .productos {
    width: 90%;
    margin: 0 auto;
  }
  .ais-SearchBox-input {
    width: 80%;
}

}
/* Menu móvil css=========================================================*/

/* Botón de filtros */
.toggle-filters-button {
  display: none; /* Ocultar en pantallas grandes */
  cursor: pointer;
  margin: 10px auto;
  font-weight: bold;
  width: 50%;
}

/* Contenedor de filtros */
.filter-container {
  display: block; /* Mostrar por defecto en pantallas grandes */
  transition: transform 0.3s ease-in-out;
}

.filter-container.visible {
  transform: translateX(0); /* Mostrar cuando sea visible */
}

/* Botón para cerrar filtros */
.close-filters-button {
  display: none; /* Ocultar por defecto */
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
}

/* Pantallas pequeñas: ocultar por defecto */
@media (max-width: 768px) {
  .toggle-filters-button {
    display: block; /* Mostrar botón en móviles */
  }

  .filter-container {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    background: rgba(255, 255, 255, 0.97); /* Fondo blanco con 90% de opacidad */
    overflow-y: auto; /* Permite el desplazamiento */
    z-index: 10;
    padding: 20px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
  

  .filter-container.visible {
    transform: translateX(0);
  }

  .close-filters-button {
    display: block; /* Mostrar el botón de cerrar en móviles */
  }
}