.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
  }
  
  /* Estilos para los botones dentro del modal */
  .modal-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column; /* Establece la dirección de los botones como columna */
    align-items: center; /* Centra los botones horizontalmente */
  }

  .modal-buttons button{
    width: 100% !important; 
    padding: 10px !important;
    text-align: center !important;
  }

  
