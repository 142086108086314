º*{
  overflow-x: hidden;
}
button {
  background-color: rgb(255, 150, 0);
  color: white;
  padding: 10px;
  margin: 0px 8px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.20s ease, color 0.20s ease;
  font-size: 0.9em;
}

button:hover{
  background-color: rgb(251, 214, 32);
  color: black;
  border: solid 1px rgb(251, 214, 32);
}