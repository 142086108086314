.signout-button {
    background-color: transparent; /* Fondo transparente */
    color: #666; /* Color de texto gris oscuro */
    padding: 8px 16px; /* Espaciado interno */
    margin: 0; /* Margen exterior */
    border: 1px solid #a2a2a2; /* Borde delgado gris claro */
    border-radius: 6px; /* Bordes redondeados */
    font-size: 14px; /* Tamaño de fuente */
    cursor: pointer; /* Cursor de puntero al pasar sobre el botón */
    transition: background-color 0.3s ease; /* Transición suave para cambio de color de fondo */
  }
  
  .signout-button:hover {
    background-color: #fb5050; /* Cambiar el fondo al hacer hover */
    color: white;
    border: none;
  }
  
  .signout-button:focus {
    outline: none; /* Eliminar el contorno al enfocar el botón */
  }
  

.signout-button p{
    padding: 0;
    margin: 0;
}
