.producto-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  }

.producto-card img {
  width: 100%;
  height: 400px;
  max-height: 100%; /* Asegura que la imagen no se desborde del contenedor */
  object-fit: cover; /* Mantiene la relación de aspecto y cubre el contenedor */
}

.producto-info {
  background-color: white;
  padding: 16px;
}

.producto-info h3 {
  margin: 20px 0 0 0;
  max-height:90px;
}

.ais-Hits-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);/* 2 columnas */
  list-style: none;
  padding: 0;
  margin: 0;
}

.ais-Hits-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Crea 4 columnas de igual ancho */
  gap: 20px; /* Espacio entre las celdas */
}

.caracteristicas li {
  margin: 5px 0;
  list-style: none;
}

.producto-info a{
  color: black;
}

.titulo-producto {
  display: -webkit-box; /* Soporte para navegadores basados en WebKit */
  display: box; /* Propiedad estándar para futuro soporte */
  -webkit-box-orient: vertical; /* Configura orientación vertical en WebKit */
  box-orient: vertical; /* Configura orientación vertical en la propiedad estándar */
  -webkit-line-clamp: 3; /* Soporte actual para limitar líneas */
  line-clamp: 3; /* Propiedad estándar para futuro soporte */
  overflow: hidden; /* Oculta contenido adicional */
  text-overflow: ellipsis; /* Muestra puntos suspensivos en el texto truncado */
  line-height: 1.5; /* Espaciado entre líneas */
  max-height: calc(1.5em * 3); /* Altura máxima equivalente a 3 líneas */
}


.botones-producto {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos horizontalmente */
}

.botones-producto a,
.botones-producto button {
  margin: 2px 0; /* Espaciado entre los botones */
  width: 100%; /* Ocupa todo el ancho disponible dentro de la anchura máxima */
}

.boton-favoritos{
  background-color: white;
  color: rgb(251, 160, 32);
  border: solid 1px rgb(251, 160, 32) ;
}

/* Estilo para el estado "Agregar a favoritos" (verde) */
.boton-favoritos.agregar:hover {
  background-color: rgb(111, 254, 158); /* Verde */
  color: rgb(54, 54, 54);
  border: solid 1px rgb(111, 254, 158);
}

/* Estilo para el estado "Quitar de favoritos" (rojo) */
.boton-favoritos.quitar:hover {
  background-color: rgb(248, 93, 93); /* Rojo */
  color: white;
  border: solid 1px rgb(248, 93, 93);
}

@media screen and (max-width: 768px) {
  .ais-Hits-list {
    grid-template-columns: repeat(2, 1fr); /* Cambiar a 2 columnas por fila */
  }
}
@media screen and (max-width: 480px) {
  .ais-Hits-list {
    grid-template-columns: repeat(1, 1fr);  }
}