.usuario {
    display: flex;
    width: 100%;
    height: 100%;

  }

.img-perfil {
    width: 60%;
    height: auto;
    max-height: 100vh;
    object-fit: cover;
}

.info-y-botones {
    padding: 5%;
    width: 40%;
  }
  
  .user-plan {
    display: flex; /* Utilizar flexbox para alinear los elementos */
    align-items: center; /* Alinear verticalmente los elementos */
  }
  
  .user-plan h1 {
    margin-right: 10px; /* Espacio entre el h1 y el p */
    margin: 0;
    margin-right: 10px;
    color:rgb(36, 36, 36);
  }

  
  .info-user p{
    margin: 5px 0;
    color:rgb(151, 151, 151);
  }

  .user-h2 h2{
    color:rgb(48, 48, 48);
    display: flex;
  }

  .premium-label{
    display: inline-block; /* Coloca el párrafo en línea con el h1 */
    padding: 2px 10px;
    background-color: rgb(107, 253, 107);
    border-radius: 20px;
    font-size: 14px;
  }

  .servicios-premium ul {
    list-style-type: none; /* Eliminar viñetas de la lista */
    padding: 0; /* Eliminar relleno interno de la lista */
  }
  
  .servicios-premium button {
    color: rgb(48, 48, 48);
    background: none; /* Fondo transparente del botón */
    border: none; /* Sin borde alrededor del botón */
    font: inherit; /* Heredar la fuente del contenedor */
    cursor: pointer; /* Mostrar cursor como puntero al pasar sobre el botón */
    text-align: left; /* Alinear texto a la izquierda dentro del botón */
    width: 50%;
    margin: 0;
    padding: 0 20px;
  }

  .go-premium p{
    color: rgb(146, 146, 146);
  }
  
  .servicios-premium button:hover {
    background-color: #f0f0f0; /* Cambiar el fondo a gris claro al hacer hover */
  }

  .servicios-premium button:hover svg {
    color: rgb(255, 150, 0); /* Cambiar el fondo a gris claro al hacer hover */
  }
  .suscribe-premium svg{
    color: rgb(255, 150, 0)
  }


.botones-user ul {
  list-style-type: none; /* Eliminar viñetas de la lista */
  padding: 0; /* Eliminar relleno interno de la lista */
}

.botones-user button {
  color: rgb(48, 48, 48);
  background: none; /* Fondo transparente del botón */
  border: none; /* Sin borde alrededor del botón */
  font: inherit; /* Heredar la fuente del contenedor */
  cursor: pointer; /* Mostrar cursor como puntero al pasar sobre el botón */
  text-align: left; /* Alinear texto a la izquierda dentro del botón */
  width: 50%;
  margin: 0;
  padding: 0 20px;
}

.botones-user button:hover {
  background-color: #f0f0f0; /* Cambiar el fondo a gris claro al hacer hover */
  color: #fa4d4d;
}

/*=======================================================================================*/

.dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 50%;
}

.info-y-botones svg{
  margin: 0 10px;
  text-align: center;
  width: 15px; /* Establecer el ancho deseado */
  height: 15px;

}


/*CARGANDO======================================================================== */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Capa superior para cubrir todo */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid rgb(251, 160, 32); /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Animación de giro */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media screen and (max-width: 768px) {
  .usuario {
    flex-direction: column; /* Cambia la dirección del flex para apilar los elementos verticalmente */
    text-align: center; /* Centra el texto */
  }

  .info-y-botones {
    width: 90%; /* La imagen ocupa el 100% del ancho en pantallas pequeñas */
    margin-bottom: 20px; /* Añade un espacio entre la imagen y el texto */
    flex-direction: column;
    align-items: flex-start;
  }

  .div-texto {
    width: 90%; /* El texto ocupa el 100% del ancho en pantallas pequeñas */
    padding: 0 20px; /* Ajusta el relleno para pantallas pequeñas */
  }
  .dropdown-toggle {
    width: 100%;
  }
  .servicios-premium button, .botones-user button, .signout-button {
    width: 90%;
  }

  .img-perfil {
    width: 100%; /* La imagen ocupa el 100% del ancho en pantallas pequeñas */
    max-height: initial; /* Elimina la altura máxima */
  }
  .puser{
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }
  .user-plan h1{
    font-size: 1.8em;
  }

}