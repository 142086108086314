/* Estilos para el contenedor principal */
.alertas {
    margin: 0 5%; 
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 100vh;
  }

  .crear-alertas {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .crear-alertas h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
  }
  
  .form-container div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .form-container label {
    width: 120px; /* Ancho fijo para todas las etiquetas */
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  
  .form-container select,
  .form-container input {
    flex: 1; /* El elemento se expande para ocupar el espacio restante */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }

  .form-container input:focus,
  .form-container select:focus {
    border-color: #ff8c00;
    outline: none;
  }
  
  .botton-crear-alerta {
    width: 100%;
    padding: 10px;
    background-color: #ff8c00;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 10px;
  }
  
  /* Estilos para la lista de alertas */
.mostrar-alertas {
    flex: 2;
}
  
.mostrar-alertas ul {
    list-style-type: none;
    padding: 0;
}
  
.mostrar-alertas li .info-alerta{
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    display: flex; /* Mostrar los elementos en una fila */
    align-items: center;
    margin: 20px 0 ;
}

.mostrar-alertas li .info-alerta p {
    margin-right: 10px; /* Aplicar margen a la izquierda de 10px en todos los elementos <strong> excepto el primero */
}

.mostrar-alertas li .info-alerta strong {
    font-weight: bold;
}

.boton-eliminar {
    padding: 10px 10px;
    background-color: #dc3545;
    color: #fff;
    border: solid 1px #dc3545;
    border-radius: 4px;
    cursor: pointer;
    margin-left: auto;
}
.boton-eliminar:hover{
  background-color: white;
  color: #dc3545;
  border: solid 1px #dc3545;
}

.products-container-alerts {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Crea 5 columnas iguales */
    gap: 20px; /* Espaciado entre los elementos */
    margin: 0px auto;
    margin-bottom: 30px;
}

@media (max-width: 768px) {
  .alertas {
    flex-direction: column;
    align-items: center;
  }

  .crear-alertas, .mostrar-alertas {
    width: 95%;
    padding: 0 20px;
  }

  .form-container div {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-container label {
    width: auto;
    margin-bottom: 5px;
  }

  .botton-crear-alerta {
    width: 100%;
    text-align: center;
  }

  .mostrar-alertas li .info-alerta {
    flex-direction: column;
    align-items: flex-start;
  }

  .boton-eliminar {
    margin-left: 0;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .products-container-alerts {
    grid-template-columns: repeat(1, 1fr); /* Cambia a 3 columnas */
  }
  .form-container select,
  .form-container input {
    flex: 1; /* El elemento se expande para ocupar el espacio restante */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 90%;
  }
}

.action-sub{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}