/* Estilo para pantalla grande (escritorio) */
.div-nosotros {
  display: flex;
  min-height: 100vh; /* Asegura que el contenedor ocupe todo el alto de la pantalla */
}

.div-texto {
  width: 55%; /* El texto ocupa el 50% del ancho */
  padding: 5%; /* Añade relleno para el contenido */
  overflow-y: auto; /* Permite que el contenido tenga scroll si es necesario */
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */
}

.img-nosotros {
  width: 45%; /* La imagen ocupa el 50% del ancho */
  object-fit: cover; /* La imagen se ajusta y recorta sin distorsionarse */
}

.div-texto div {
  margin: 30px 0;
}

.div-texto h2 {
  margin: 15px 0;
}

/* Estilo para pantallas pequeñas (móviles) */
@media screen and (max-width: 768px) {
  .div-nosotros {
    flex-direction: column; /* Apila los elementos verticalmente */
    text-align: center; /* Centra el texto */
  }

  .img-nosotros {
    width: 100%; /* La imagen ocupa el 100% del ancho en pantallas pequeñas */
    height: 40vh; /* Ajusta la altura de la imagen en pantallas pequeñas */
  }

  .div-texto {
    width: 100%; /* El texto ocupa el 100% del ancho en pantallas pequeñas */
    padding: 0 20px; /* Ajusta el padding en pantallas pequeñas */
  }
}

.paginas-legales {
  display: flex;
  flex-direction: column;
}

.paginas-legales a {
  color: #e0e0e0;
  text-decoration: none;
  margin-bottom: 10px;
}

.paginas-legales a:hover {
  color: #505050;
}

/*============================ Q & A =========================================*/

/* Estilo para la sección de preguntas y respuestas */
.faq-section {
  margin-top: 30px;
}

.faq-section h2 {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Estilo de cada pregunta */
.faq-item {
  margin: 0 !important;
}

.faq-question {
  width: 100%;
  text-align: left;
  background-color: #ffffff; /* Color de fondo gris */
  color: #333; /* Color del texto */
  padding: 15px;
  font-size: 1.1em;
  border-top: solid 1px;
  border-color: #A9A9A9;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
  border-radius: 0px;
  margin: 0
}

.faq-question:hover {
  background-color: #ffffff; /* Color de fondo gris más oscuro cuando se pasa el ratón */
  border-color: #ffffff;
  border-top: solid 1px;
}

.faq-arrow {
  font-size: 1.2em;
  transition: transform 0.3s ease;
  transform: rotate(0deg); /* Dirección inicial de la flecha */
}

.faq-arrow.open {
  transform: rotate(180deg); /* La flecha se gira cuando la respuesta está abierta */
}

/* Estilo de las respuestas */
.faq-answer {
  padding: 10px;
  margin: 0 !important;
}

.faq-answer p {
  margin-top: 10px;
}


/*============================ Q & A =========================================*/

/* Estilo para el enlace dentro del botón */
button .tutorial-boton {
  text-decoration: none; /* Sin subrayado */
  color: white; /* Color del texto */
  font-weight: bold;
}