.navegador-principal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgb(255, 150, 0); /* Color de fondo de la barra de navegación */
}

.navegador-principal  h2 {
  margin: 0; /* Elimina el margen predeterminado del h2 */
}

.navegador-principal  ul {
  list-style: none; /* Elimina los estilos de la lista */
  padding: 0; /* Elimina el relleno predeterminado de la lista */
  display: flex;
}

.navegador-principal  ul li {
  margin-right: 20px; /* Espacio entre elementos de la lista */
}

.navegador-principal a {
  text-decoration: none; /* Elimina la subrayado predeterminado de los enlaces */
  color: white; /* Color del texto de los enlaces */
}