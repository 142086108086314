.login {
    display: flex;
    align-items: center;
  }
  
.login-texto {
    width: 50%; /* O ajusta el tamaño según sea necesario */
    padding: 0 100px; /* Añade relleno para separar del borde */
  }

.img-login {
    width: 50%;
    height: 95vh;
    object-fit: cover;
  }

.login-texto {
    padding: 0 7%;
  }
  
.login-texto h1 {
    margin-bottom: 10px;
  }
  
.login-texto p {
    margin-bottom: 15px;
  }
  
.login-texto ul {
    margin-bottom: 15px;
  }
  
.login-texto li {
    line-height: 1.5;
    margin-left: 25px;
  }

.login-texto button{
    width: 100%;
}

.boton-login{
  padding: 10px;
  margin: 10px;
  max-width: 200px;
  text-align: center;
}
.botones-login {
  display: flex; /* Hace que los botones se coloquen en línea */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Asegura alineación vertical */
  gap: 20px; /* Espaciado entre los botones */
}
/* Estilo para pantallas pequeñas (móviles) */
@media screen and (max-width: 768px) {
    .login {
      flex-direction: column; /* Cambia la dirección del flex para apilar los elementos verticalmente */
    }
  
    .img-login {
      width: 100%; /* La imagen ocupa el 100% del ancho en pantallas pequeñas */
    }
  
    .login-texto {
      width: 100%; /* El texto ocupa el 100% del ancho en pantallas pequeñas */
      padding: 0 20px; /* Ajusta el relleno para pantallas pequeñas */
      margin: 50px 0; /* Añade un espacio entre la imagen y el texto */

    }
    .login-texto li {
        line-height: 1.5;
        margin-left: 25px;
      }
  }


  @media screen and (max-width: 768px) {
  
    .login-texto {
      width: 90%; /* El texto ocupa el 100% del ancho en pantallas pequeñas */
      padding: 20px; /* Ajusta el relleno para pantallas pequeñas */
      margin: 20px 0; /* Añade un espacio entre la imagen y el texto */
    }
  
  }