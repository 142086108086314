.producto-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    }
  
  .producto-card img {
    width: 100%;
    height: 400px;
    max-height: 100%; /* Asegura que la imagen no se desborde del contenedor */
    object-fit: cover; /* Mantiene la relación de aspecto y cubre el contenedor */
  }
  
  .producto-info {
    padding: 16px;
  }
  
  .producto-info h3 {
    margin: 20px 0 0 0;
    min-height:70px;
  }
  
  .caracteristicas {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* 2 columnas */
    grid-template-rows: repeat(4, auto); /* 3 filas, ajusta según sea necesario */
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .caracteristicas li {
    margin: 5px 0;
  }
  
  .producto-info a{
    color: black;
  }
  
  .botones-producto {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos horizontalmente */
  }
  
  .botones-producto a,
  .botones-producto button {
    margin: 2px 0; /* Espaciado entre los botones */
    width: 100%; /* Ocupa todo el ancho disponible dentro de la anchura máxima */
  }