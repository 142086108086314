.contact-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 40%;
    text-align: center;
    margin: 0 auto;
  }
  
.contact-form label {
    display: block;
    margin: 10px 0 5px;
    color: #555;
  }
  
.contact-form  input,
.contact-form  textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  
.contact-form.contact-form  .error {
    color: #ff0000;
    font-size: 12px;
  }
  
.contact-form  button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
  }
  
.contact-form  button:hover {
    background-color: #0056b3;
  }
  