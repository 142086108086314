
/* Estilos del contenedor principal */
.afiliados-dashboard {
    max-width: 1000px;
    min-height: 90vh;
    margin: 0 auto;
    padding: 20px;
}
.afiliados-dashboard h1 {
  text-align: center;
}
/* Estilos para el selector de rango de fechas */
.date-range-selector {
    display: flex;
    align-items: center;
    justify-content: center;  /* Centra los elementos de forma horizontal */
    gap: 20px; /* Espacio entre los elementos */
    margin-bottom: 30px;
  }
  
  .date-range-selector label {
    font-size: 1rem;
    color: #555;
    margin-right: 5px; /* Para darle un pequeño margen entre el label y el input */
  }
  
  .date-range-selector input[type="date"] {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
    width: 180px; /* Limitar el ancho de los inputs */
  }

.date-range-selector input[type="date"]:focus {
    border-color: #ffa03b;
}
.charts-section h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.commission-section h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
}

.commission-section p {
    font-size: 1.2rem;
    color: #333;
    text-align: center;
    margin: 10px 0;
}

.no-records-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 550px;
    font-family: 'Arial', sans-serif;
    color: #ff8903; /* Color de texto rojo para destacar */
    background-color: #f6f5f5; /* Fondo suave para resaltar el mensaje */
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    font-size: 18px;
    margin: 20px 0;
  }
  
  .no-records-message p {
    margin: 0;
    font-weight: bold;
  }
  

/* Estilos para la responsividad */
@media (max-width: 768px) {
    .afiliados-dashboard {
    padding: 15px;
    }

    .date-range-selector {
    flex-direction: column;
    gap: 10px;
    }

    .date-range-selector label {
    font-size: 1rem;
    }

    .date-range-selector input[type="date"] {
    width: 100%;
    }

    .charts-section h2 {
    font-size: 1.6rem;
    }

    .commission-section h2 {
    font-size: 1.6rem;
    }

    .commission-section p {
    font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .date-range-selector label {
    font-size: 0.9rem;
    }
    .date-range-selector input[type="date"] {
    padding: 8px;
    }

    .commission-section h2 {
    font-size: 1.4rem;
    }

    .commission-section p {
    font-size: 1rem;
    }
}
