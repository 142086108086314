.registro-container {
    text-align: center;
    margin: 20px;
    height: 100vh;
}

.display-name {
    color: rgb(0, 182, 15); /* Cambia el color a tu preferencia */
}

.error-message {
    color: #e74c3c; /* Color para mensajes de error */
    font-weight: bold;
}

.input-container,
.button-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-container label {
    margin-bottom: 5px;
    font-weight: bold;
}

.input-container input,
.input-container select {
    padding: 10px;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
    margin-bottom: 20px; /* Añadir margen inferior para separar los inputs */
}

.username-input {
    width: 100%;
}

.registro-exitoso {
    text-align: center;
    margin: 10% auto;
    height: 100vh;
}

.registro-exitoso h1 {
    color: #2ecc71; /* Color para mensajes de éxito */
    margin-bottom: 50px;
}

.buscar-productos-link {
    color: black;
    text-decoration: none;
}

.continue-button {
    padding: 10px 20px;
    background-color: #f39c12;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .registro-exitoso h1 {
        color: #2ecc71; /* Color para mensajes de éxito */
        padding: 0 5%;
        margin-bottom: 50px;
    }

    .input-container input,
    .input-container select {
        width: 90%; /* Ajustar el ancho para pantallas pequeñas */
    }
}
