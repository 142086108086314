/* Estilos generales para el contenedor de planes */
.subscription-plans {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
}

/* Estilos para el contenedor de todos los planes */
.todos-planes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

/* Estilos para cada plan de suscripción */
.plan {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

/* Estilos para el título del plan */
.plan-name {
  font-size: 24px;
  color: #555;
}

/* Estilos para la descripción del plan */
.plan-description {
  font-size: 16px;
  color: #777;
}

/* Estilos para el precio */
.plan-price {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Estilos para el botón de suscripción */
.subscribe-btn {
  padding: 10px 20px;
  font-size: 18px;
}



.parrafo-planes {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.3;
}

.parrafo-planes p {
  margin-bottom: 0px;
}

.parrafo-planes ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0px;
}

.parrafo-planes li {
  margin-bottom: 0px;
}

.parrafo-planes a {
  color: #007bff;
  text-decoration: none;
}

.parrafo-planes a:hover {
  text-decoration: underline;
}


/*CARGANDO======================================================================== */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Capa superior para cubrir todo */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid rgb(251, 160, 32); /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Animación de giro */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/*Parrafo planes======================================================================== */
.parrafo-planes {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  margin: 5% 25% ;
  text-align: center;
  background-color: #e2f6e0; /* Fondo suave */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.parrafo-planes p {
  font-size: 18px;
  margin-bottom: 15px;
}
.parrafo-planes li {
  margin-bottom: 10px;
  font-size: 16px;
  text-align: left;
  display: flex;
  align-items: center;
}

.parrafo-planes li::before {
  content: "✔️"; /* Agrega un ícono de check antes de cada ítem */
  margin-right: 10px;
  color: #ff9800;
}

@media (max-width: 768px) {

.subscription-plans {
  width: 80%;
  margin: 0 auto;
}
.plan {
  width: 90%;
}
.parrafo-planes{
  margin: 5% 0;
  padding: 10px;
}
}